#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.mainLayoutContent {
  height: 100vh;
}

.loginForm {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loginFormContainer {
  background: #fff;
  height: 80vh;
}

.initialsBuble {
  background: #f0f2f5;
  color: #1791ff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  font-weight: bold;
  font-size: 12px;
}

.iconContainer {
  text-align: center;
  font-size: 75px;
  font-family: secular;
  text-align: center;
  color: #1791ff;
}

.logoMenuItem {
  opacity: 1;
  order: 0;
  font-family: secular;
  padding-left: 0;
  left: -30px;
  width: 135px;
}

.newMenuItem {
  font-size: 10px !important;
  background-color: #b289ff !important;
  height: auto !important;
  padding: 4px 4px !important;
  line-height: 10px !important;
  top: 5px !important;
}
@font-face {
  font-family: 'Secular';
  src: local('Secular'), url(./SecularOne-Regular.ttf) format('truetype');
}

:root {
  --primary-color: blue;
}

/* .test {
  background: var(--primary-color);
} */

.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #fff !important; */
}

.loading-box .ant-layout-sider-children {
  height: auto;
}

.filters-hosts.ant-menu-submenu-active {
  color: #000;
}

.filter:not(:last-child) {
  border-bottom: 3px solid #f0f2f5;
}

.ant-layout-header,
.ant-layout-header .ant-menu,
.ant-layout-header .ant-menu a,
.ant-layout-header .ant-menu .ant-menu-title-content
 {
  color: #fff !important;
  background-color: #1a90ff;
}

.ant-drawer-content {
  z-index: 99;
}

.tracker-panel .ant-collapse-content {
  background: #fff !important;
}


.meetingItemActionContainer .ant-card-head {
  transition: ease-in-out 0.2s;
  opacity: 0;
  max-width: 10%;
  display: inline-block;
  position: absolute;
  right: 0;
  border: none;
  cursor: pointer;
}

.meetingItemActionContainer:hover .ant-card-head {
  transition: ease-in-out 0.2s;
  opacity: 1;
}

.libraryCards .ant-card-body {
  background: #fff;
}

.no-library-meetings {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%);
}

.btn-doredo {
  background: none !important;
}

.section-area-container {
  background: #fff;
  z-index: 2;
}
.section-content-container {
  padding-top: 60px;
}

.section-area-container .ant-menu-item > span {
  line-height: 40px;
}

.section-area-container .section-title {
  color: #1791ff;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  box-shadow: 1px 1px 3px #eee;
}

.brain-loader-container .ant-progress-inner {
  min-height: 200px;
  min-width: 200px;
}
.summary-list-item {
  color: rgba(0, 0, 0, 0.45);
  margin-top: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.section-content-scroll {
  padding: 20px;
  max-height: 88vh;
  overflow-y: scroll;
}

.search-section-container .section-content-scroll {
  overflow-y: visible;
}

.meeting-header-detail-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}

.meeting-header-detail-container .samu-score-item-container {
  padding: 0 15px 0 0 !important;
}


.activity-tabs {
  color: #5b5858;
}

.activity-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
}

.activity-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff;
}
.activity-tabs .ant-tabs-nav {
  margin: 0;
}

.activity-tabs .ant-tabs-content {
  background: #fff;
  padding: 15px;
}

.activity-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.activity-tabs .ant-tabs-tab.ant-tabs-tab > div,
.activity-tabs .ant-tabs-tab + .ant-tabs-tab {
  padding: 0 25px 0 10px;
}

.activity-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  top: 0 !important;
  bottom: initial !important;
  height: 3px;
  background: #B388FF;
}

.topTabsContainer > .ant-tabs-nav  {
  border-bottom: 3px solid #82B1FF;
  margin-top: 20px;
}

.tabContent2Items {
  display: inline-block;
  width: 50%;
  padding: 0 10px;
}

.activity-tabs .ant-tabs-nav-list .ant-tabs-tab:not(:last-child) {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: none !important;
}

.activity-tabs .ant-tabs-nav-list  {
  border-right: 1px solid #ccc;
}

.activity-tabs .ant-tabs-content-holder {
  border: 1px solid #ccc;
}

.sectionCardHeader .ant-card-head {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px;
  color: #fff !important;
}

.crm-filter-deal-select {
  width: 100% !important;
  display: block;
}

